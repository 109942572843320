<template>
  <v-container>
    <v-card>
      <v-toolbar dense flat class="teal" dark>
        <v-toolbar-title>Adicionar critério</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>

      <v-card-text>
        <form>
          <v-text-field
            v-model="name"
            :error-messages="nameErrors"
            :counter="100"
            label="Nome do critério"
            placeholder="...fibra rompida"
            required
            outline
            @input="$v.name.$touch()"
            @blur="$v.name.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="weight"
            :error-messages="weightErrors"
            label="Peso do critério"
            type="number"
            placeholder="100"
            required
            outline
            @input="$v.weight.$touch()"
            @blur="$v.weight.$touch()"
          ></v-text-field>
          <v-btn
            :disabled="$v.name.$invalid || $v.weight.$invalid"
            class="mr-4"
            @click="submit"
          >
            Cadastrar
          </v-btn>
        </form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    name: { required },
    weight: { required }
  },
  data: () => ({
    name: "",
    weight: 0
  }),
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Nome é um campo obrigatório!");
      return errors;
    },
    weightErrors() {
      const errors = [];
      if (!this.$v.weight.$dirty) return errors;
      !this.$v.weight.required && errors.push("Peso é um campo obrigatório!");
      return errors;
    }
  },
  methods: {
    async submit() {
      try {
        const response = await this.$http.post("/criteria", {
          name: this.name,
          weight: this.weight
        });

        if (response) return this.$toast.success("Cadastrado com sucesso!");
      } catch (error) {
        if (error.response.status === 400) {
          return this.$toast.error(
            "Falha ao cadastrar critério, tente novamente!!!"
          );
        }
        return this.$toast.error("Tente novamente mais tarde!");
      }
    },
    clear() {
      this.$v.$reset();
      this.name = "";
      this.weight = "";
    }
  }
};
</script>

<style></style>
